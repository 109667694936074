<template>
  <div class="wrapper cs_login_page">
      <div v-if="!d_sessionExpired && d_showLoginForm">
        <div style="width: 100%; display:grid; padding-top:2rem">
          <Image style="justify-self: center" class="cs_image" :src="d_imagePath"></Image>
        </div>
        <form class="login_form"
              style="display: grid; grid-template-columns: 7rem auto"
              @submit.prevent="onSubmit"
              @keyup.enter="m_submitForm" 
              >

          <label class="form_label" for="id_user_name_field">User Name</label>

          <InputText v-focus class="password_input"
                     style="width: 25.9rem"
                     id="id_user_name_field"
                     v-model="d_userNameValue"
                     autocomplete="on" />

          <label class="form_label" for="id_password_field">Password</label>

          <Password v-focus
                    id="id_password_field"
                    v-model="d_passwordValue"
                    autocomplete="off"
                    toggleMask
                    :feedback="false" />
        </form>

        <div class="cs_login_buttons cs_buttons_parent">
          <div v-if="m_getProductionMode()">
          </div>
          <div v-else>
            <Button id="id_login_load_existing_button" class="g_cs_button_fix p-button-rounded button" @click="loadExisting">Load</Button>
          </div>
          <Button id="login_submit_button_id" class="g_cs_button_fix p-button-rounded" @click="m_submitForm">Submit</Button>
        </div>
        <div style="padding-left: 2rem" class="cs_login_buttons cs_buttons_parent">
            <Button @click="m_forgotPasswordCallback" label="Link" class="g_cs_link_fix p-button-link" >I forgot my password</Button>
        </div>
        <div style="padding-left: 2rem" class="cs_login_buttons cs_buttons_parent cs_bottom_center">
            <Button @click="m_privacyPolicyCallback" label="Link" class="g_cs_link_fix p-button-link" >Privacy Policy</Button>
        </div>
      </div>
      <div class="login_form" v-else-if="d_sessionExpired">
        <div style="padding-left: 2rem; display: grid; grid-template-rows: auto auto; align-items: center; grid-gap: 1rem; justify-items: center;" class="cs_login_buttons">
            <label>Your session has expired. Please click the button to return to the login page</label>
            <Button id="return_to_login_page_id" class="g_cs_button_fix p-button-rounded" @click="m_sessionExpiredCallback">Go to Login Page</Button>
        </div>
      </div>
      <div v-if="d_showResetPasswordForm">
        <form class="reset_parent_form" @submit.prevent="onSubmit">
          <label style="font-weight: 600; font-size: +1">Recover Password</label>
          <label>Please enter your user name and email, then click Submit</label>

          <div class="reset_form">
            
            <label class="reset_label">User Name</label>
            <InputText v-focus style="width: 22rem" id="id_user_name_field" v-model="d_resetPasswordUserName" autocomplete="on" />

            <label class="reset_label">Email</label>
            <InputText v-focus style="width: 22rem" id="id_user_email_field" v-model="d_resetPasswordEmail" autocomplete="on" />

          </div>
          <div :style="m_getButtonParentStyle()">
            <Button id="login_submit_button_id" class="g_cs_button_fix p-button-rounded" @click="m_cancelResetPasswordCallback">Cancel</Button>
            <Button class="g_cs_button_fix p-button-rounded" @click="m_submitUserNameForPasswordRecoveryCallback">Submit</Button>
          </div>
        </form>
      </div>

      <Dialog header="One Time Passcode" v-model:visible="d_showMfaOtpDialog" :style="{width: '31rem'}" :modal="true" :closable="false">
        <form @submit.prevent="onSubmit" @keyup.enter="m_submitMfaOtpCallback" >
          <div class="c_info_panel" style="margin-top: 1.0rem; margin-bottom: 0.5rem; padding-top: 0.0rem; font-size: large">
            <div style="display: grid; grid-template-columns: auto; justify-self: center; align-self: center; padding-top: 1.0rem; grid-gap: 0.0rem">

              <div  style="display: grid; align-items: center; grid-template-rows: auto auto; justify-content: center; grid-gap: 0.5rem" >
                  <div style="display: grid; align-items: center; grid-template-columns: auto auto; justify-content: center; grid-gap: 0.5rem; justify-self: center">
                    <icon v-if="d_numbeMfaOtpFailures > 0" class="pi pi-exclamation-circle"></icon>
                    <label>{{ d_mfaOtpMessage }}</label>
                  </div>
                  <div v-if="d_numbeMfaOtpFailures !== 3" id="otp_entry_id" style="justify-self: center">
                    <InputMask v-focus mask="999999" @keyup="m_checkMfaOtpLength" style="width: 5.0rem" v-model="d_mfaOtpValue" />
                  </div>
              </div>
            </div>
          </div>
        </form>

        <div style="display: grid; grid-template-columns: auto auto; justify-content: center; padding-top: 1rem; grid-gap: 1rem">
          <Button id="cancel_mfa_otp_button_id" class="g_cs_button_fix p-button-rounded" @click="m_cancelMfaOtpCallback">Cancel</Button>
          <Button id="submit_mfa_otp_button_id"
                  :disabled="!d_enableSubmitMfaOtp"
                  class="g_cs_button_fix p-button-rounded"
                  @click="m_submitMfaOtpCallback">
            Submit
          </Button>
        </div>
      </Dialog>
      <ChangePasswordComponent :p_showChangePasswordDialog="d_showChangePasswordDialog"
                               :p_submitChangedPasswordCallback="m_submitChangedPasswordCallback" 
                               :p_forcePasswordChange="m_getForcePasswordChange()" 
                               :p_newPassword="d_passwordValue" />

      <ChangePasswordComponent :p_showChangePasswordDialog="d_showResetPasswordDialog"
                               :p_recoveryCode="m_getRecoveryCode()"
                               :p_submitResetPasswordCallback="m_submitResetPasswordCallback" />

      <Dialog header="Login Error" 
              v-model:visible="d_showLoginError" 
              :style="{width: '32rem'}" 
              :maximizable="false" 
              :modal="true" 
              :closable="false">

        <div style="display: grid; padding-top: 2rem; grid-template-rows: 2rem 4rem; align-items: center">
          <h3>{{d_loginErrorMessage}}</h3>
          <Button autofocus class="g_cs_button_fix p-button-rounded c_button" style="margin-top: 1.5rem; justify-self: center; width: 5rem" @click="m_hideLoginErrorDialog">OK</Button>
        </div>
      </Dialog>


      <div v-if="d_showRecoverPasswordConfirmation">
        <form class="reset_confirmation_parent_form " @submit.prevent="onSubmit">
          <label style="font-weight: 600; font-size: +1; padding-bottom: 2rem; padding-top: 1rem">Email has been sent, please follow the instructions in the email</label>
          <Button class="g_cs_button_fix p-button-rounded" @click="m_returnToLoginForm">OK</Button>
        </form>
      </div>

  </div>
</template>

<script>
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent";
import InputMask from "primevue/inputmask";
import Password from "primevue/password";
import Dialog from "primevue/dialog";
import "primeicons/primeicons.css";
import Toast from "primevue/toast";
import Image from 'primevue/image'

import { PORTAL_PYTHON_URL } from '../utils/cs_globals.js'
import g_callPythonApi, {g_getErrorMsg, g_submitMfaOtp, g_responseHasError, g_showErrorToast, g_submitForm } from "../utils/rest_manager.js";
import {g_consoleLogVue, PRODUCTION_MODE} from "../utils/cs_globals.js";
import {g_callPythonApiWithArgs, g_changePassword} from "../utils/rest_manager.js";
import {CsRestMethod} from "@/utils/cs_constants";
import ChangePasswordComponent from "./ChangePasswordComponent";
import vuexStore from "../store.js";

const LOGIN_ENDPOINT = "login";
const INITIATE_RESET_PASSWORD_ENDPOINT = "initiate_reset_password";
const COMPLETE_RESET_PASSWORD_ENDPOINT = "complete_reset_password";
const LOAD_EXISTING_DATA_ENDPOINT = "existing_user";
const DEFAULT_MFA_OTP_MESSAGE = "Please enter 6-digit Passcode";

/**
 * Enables user to login to the app, links with forgot password as well.
 */
export default {
  name: "LoginComponent",
  components: {
    Button,
    ChangePasswordComponent,
    Image,
    Dialog,
    InputText,
    InputMask,
    Password,
    Toast,
    ConfirmationDialogComponent,
  },
  data: function () {
    return {
      d_userNameValue: '',
      d_resetPasswordEmail: '',
      d_passwordValue: null,
      d_fromRegistration: false,
      d_userCount: 0, //Math.floor(Math.random() * 100)
      d_productionMode: PRODUCTION_MODE,
      d_imagePath: `${PORTAL_PYTHON_URL}/logo`,
      d_showLoginError: false,
      d_showChangePasswordDialog: false,
      d_showResetPasswordDialog: false,
      d_showLoginForm: true,
      d_showResetPasswordForm: false,
      d_showRecoverPasswordConfirmation: false,
      d_loginErrorMessage: null,
      d_recoveryCode: null,
      d_forcePasswordChange: false,
      d_sessionExpired: false,
      d_initialized: false,
      d_showMfaOtpDialog: false,
      d_enableSubmitMfaOtp: false,
      d_mfaOtpValue: null,
      d_mfaOtpMessage: DEFAULT_MFA_OTP_MESSAGE,
      d_numbeMfaOtpFailures: 0,
      d_resetPasswordUserName: null,
    }
  },
  directives: {
    focus: {
      // directive definition
      inserted: function (el) {
        el.focus()
      }
    }
  },
  async mounted() {

    const sessionExpired = vuexStore.state.s_sessionExpired;

    if (sessionExpired) {
      this.d_sessionExpired = true;
      vuexStore.commit('changeGlobalStateSessionExpired', false);
    }

    const userName = this.$route.query.user;  
    const password = this.$route.query.pass;  

    const recoveryCode = this.$route.query.recoveryCode;  

    this.$router.replace({'query': null});

    if (recoveryCode) {
      this.d_showLoginForm = false;
      this.d_recoveryCode = recoveryCode;
      this.d_showResetPasswordDialog = true;
    }
    else if (userName && password) {
      this.d_userNameValue = userName;
      this.d_passwordValue = password;
      this.d_fromRegistration = true;
      const fromRegistration = true;
      await this.m_submitForm(fromRegistration);
   }
  },
  methods: {
    m_getCancelLabel() {
      return "Reset";
    },
    m_getSubmitLabel() {
      return "Login";
    },
    m_hideDialogs() {
      this.d_showChangePasswordDialog = false;
      this.d_showLoginForm = false;
      this.d_showRecoverPasswordConfirmation = false;
      this.d_showResetPasswordForm = false;
    },
    m_showLoginPageCallback() {
      this.m_hideDialogs();
      this.d_showLoginForm = true
    },
    m_showChangePasswordCallback() {
      this.m_hideDialogs();
      this.d_showChangePasswordDialog = true
    },
    m_sessionExpiredCallback() {
      this.d_sessionExpired = false;
    },
    m_getForcePasswordChange() {
      return this.d_forcePasswordChange;
    },
    m_getRecoveryCode() {
      return this.d_recoveryCode;
    },
    m_returnToLoginForm() {
      this.d_showLoginForm = true;
      this.d_showResetPasswordForm = false;
      this.d_showRecoverPasswordConfirmation = false;
      this.d_resetPasswordUserName = null;
    },
    m_cancelResetPasswordCallback() {
      this.m_returnToLoginForm();
    },
    async m_submitUserNameForPasswordRecoveryCallback() {
      const userUsername =  this.d_resetPasswordUserName;
      const userEmail = this.d_resetPasswordEmail;
      const inputParameters = {params: { user_name: userUsername, user_email: userEmail }};
      const theArgsInput = { endpoint: INITIATE_RESET_PASSWORD_ENDPOINT, inputParameters: inputParameters, method: CsRestMethod.POST };

      let [response, errorMsg] = await g_callPythonApiWithArgs(theArgsInput);

      if (!response || errorMsg) {
        this.d_resetPasswordUserName = null;
        this.d_resetPasswordEmail = null;
        this.d_showLoginError = true
        return;
      }

      errorMsg = response.data.error;

      if (errorMsg) {
        this.d_resetPasswordUserName = null;
        this.d_resetPasswordEmail = null;
        this.d_loginErrorMessage = errorMsg;
        this.d_showLoginError = true
        return;
      }

      this.d_resetPasswordUserName = null;
      this.d_resetPasswordEmail = null;
      this.d_showResetPasswordForm = false;
      this.d_showRecoverPasswordConfirmation = true;
    },
    m_getButtonParentStyle() {
      let style = {
        'padding-top': '1rem',
        'display': 'grid',
        'align-items': 'center',
        'grid-gap': '1rem',
        'justify-content': 'center',
        'grid-template-columns': 'auto auto'
      }
      return style;
    },
    m_forgotPasswordCallback() {
      this.d_showLoginForm = false;
      this.d_showResetPasswordForm = true;
      this.d_showRecoverPasswordConfirmation = false;
    },
    m_privacyPolicyCallback() {
      const url = "https://cairn-st-p-info.s3.eu-north-1.amazonaws.com/ROOT/doc/privacy_policy.html";
      window.open(url);
    },
    m_hideLoginErrorDialog() {
      this.m_resetToLogin();
    },
    m_resetToLogin() {
      this.d_showLoginError = false;
      this.$router.replace('/login');
      window.location.reload();
      window.location.href = '/login';
    },
    m_getProductionMode() {
      const productionMode = this.d_productionMode;
      return productionMode;
    },
    async m_submitForm(fromRegistration=false) {
      this.d_showLoginForm = false;
      const inputParameters = {params: { user_name: this.d_userNameValue, user_password: this.d_passwordValue, from_registration: fromRegistration}};
      const theArgsInput = { endpoint: LOGIN_ENDPOINT, inputParameters: inputParameters, method: CsRestMethod.POST };

      let [response, errorMsg] = await g_callPythonApiWithArgs(theArgsInput);

      if (g_responseHasError(response, errorMsg)) {
        errorMsg = g_getErrorMsg(response, errorMsg)
        this.d_showLoginForm = true;
        this.d_showLoginError = true
        return;
      }

      const data = response.data;
      const loginSuccessful = data.login_successful;
      this.d_okButtonText = data.ok_button_text;

      if (!loginSuccessful) {
        if (data.reason === "USER_DEACTIVATED") {
          this.d_loginErrorMessage = "This user account has been deactivated";
        }
        else {
          this.d_loginErrorMessage = "Incorrect user name or password. Please try again";
        }

        this.d_showLoginError = true
        return;
      }

      const forcePasswordChange = data.force_password_change;
      const forceMfaOtp = data.force_mfa_otp;

      if (forcePasswordChange) {
        this.d_forcePasswordChange = forcePasswordChange;
        this.d_showChangePasswordDialog = true;
      }
      else if (forceMfaOtp) {
        this.d_showMfaOtpDialog = true;
      }
      else {
        this.setGlobalUserNameAndLogin();
      }
    },
    async m_submitResetPasswordCallback(userName, recoveryCode, passwordValue) {
      const inputParameters = {params: { user_name: userName, recovery_code: recoveryCode, password: passwordValue }};
      const theArgsInput = { endpoint: COMPLETE_RESET_PASSWORD_ENDPOINT, inputParameters: inputParameters, method: CsRestMethod.POST };

      let [response, errorMsg] = await g_callPythonApiWithArgs(theArgsInput);

      if (g_responseHasError(response, errorMsg)) {
        this.d_showChangePasswordDialog = true;
        return;
      }
      else {
        this.d_showChangePasswordDialog = false
        this.d_userNameValue = userName;
        this.d_passwordValue = passwordValue;

        await this.m_submitForm()
      }
    },
    async m_submitChangedPasswordCallback(oldPassword, newPassword, forcePasswordChange) {
      const oldPassword2 = this.d_passwordValue;

      let success = await g_changePassword(this.d_userNameValue, oldPassword2, newPassword, forcePasswordChange);

      if (success) {
        this.d_showChangePasswordDialog = false
        this.setGlobalUserNameAndLogin();
      }
      else {
        g_showErrorToast("Password change not successful", this);
      }

      return success;
    },
    m_checkMfaOtpLength() {
      const otpValue = this.d_mfaOtpValue ? this.d_mfaOtpValue.replaceAll("_", "") : "";
      const lengthMfaOtp = this.d_mfaOtpValue ? Number(otpValue.length) : 0;
      // console.log(":: enable", {otpValue,lengthMfaOtp});

      if (lengthMfaOtp < 6) {
        // console.log("::: disable")
        this.d_enableSubmitMfaOtp = false;
      }
      else {
        // console.log("::: enable")
        this.d_enableSubmitMfaOtp = true;
      }
    },
    m_cancelMfaOtpCallback() {
      this.d_showMfaOtpDialog = false;
      this.m_resetToLogin();
    },
    async m_submitMfaOtpCallback() {
      const mfaOtpValue = this.d_mfaOtpValue;
      const userName = this.d_userNameValue;
      const userPassword = this.d_passwordValue;
      let [response, errorMsg] = await g_submitMfaOtp(userName, userPassword, mfaOtpValue);

      if (g_responseHasError(response, errorMsg)) {
        errorMsg = g_getErrorMsg(response, errorMsg);
      }

      if (errorMsg === null) {
        this.d_showMfaOtpDialog = false;
        this.setGlobalUserNameAndLogin();
      }
      else {
        this.d_mfaOtpValue = null;
        this.d_enableSubmitMfaOtp = false;
        this.d_numbeMfaOtpFailures++;
        const numberRemainingFailures = 3 - this.d_numbeMfaOtpFailures;

        if (numberRemainingFailures <= 0) {
          this.d_mfaOtpMessage = "Number tries exceeded, returning to login page"
          setTimeout(() => {
            this.d_numbeMfaOtpFailures = 0;
            this.d_showMfaOtpDialog = false;
            this.d_mfaOtpMessage = DEFAULT_MFA_OTP_MESSAGE;
            this.m_resetToLogin();
          }, 7000);
        }
        else {
          this.d_mfaOtpMessage = "Code Incorrect, please try again"
        }
      }
    },
    setGlobalUserNameAndLogin() {
      // GOTO_ORDERS_ISSUE
      this.$store.commit('changeGlobalStateUserName', this.d_userNameValue);

      this.$router.replace('/orders');

      // DAX: neither of the two lines were here before Jan 20
      // window.location.reload();
      // window.location.href = '/';
    },
    async loadExisting(formName) {

      let params = { user_count: this.d_userCount };
      const inputParameters = {params: params };

      let [response, errorMsg] = await g_callPythonApi(LOAD_EXISTING_DATA_ENDPOINT, inputParameters);

      if (g_responseHasError(response, errorMsg)) {
        errorMsg = g_getErrorMsg(response, errorMsg)
        g_showErrorToast(errorMsg, this);
        return;
      }

      this.d_userNameValue = response.data.user_name;
      this.d_passwordValue = response.data.user_password;

      this.d_userCount++;
    },
  }
}
</script>

<style>
@import '../css/cs_globals.css';
</style>
<style scoped>  /* "scoped" attribute limit the CSS to this component only */

* {
  box-sizing: border-box;
}

.new_password {
  padding: 1em;
  border: 1px solid #c1c1c1;
  margin-top: 1rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}

.reset_confirmation_parent_form {
  display: grid;
  grid-template-rows: 4rem 2rem;
  justify-items: center;
  padding: 1em;
  background: #f9f9f9;
  border: 1px solid #c1c1c1;
  margin-top: 2rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}

.reset_parent_form {
  display: grid;
  grid-template-rows: 2rem .2rem auto;
  justify-items: center;
  padding: 1em;
  background: #f9f9f9;
  border: 1px solid #c1c1c1;
  margin-top: 2rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}

.reset_form {
  display: grid;
  grid-template-columns: 7rem 35rem;
  padding-left: 5rem;
  background: #f9f9f9;
  margin-top: 2rem;
  width: 35rem;
  padding: 1em;
  grid-gap: .5rem;
}

.login_form {
  padding: 1em;
  background: #f9f9f9;
  border: 1px solid #c1c1c1;
  margin-top: 2rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}

form .password-input {
  margin-bottom: 1rem;
  background: #fff;
  border: 1px solid #9c9c9c;
}

.cs_one_button_parent {
  padding-top: 1rem;
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  grid-gap: 1rem;
  justify-content: center;
}

.cs_buttons_parent {
  padding-top: 1rem;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  grid-gap: 1rem;
  justify-content: center;
}

label.reset_label {
  text-align: right;
  display: block;
  padding: 0.5em 1.5em 0.5em 0;
}

label.form_label {
  text-align: right;
  display: block;
  padding: 0.5em 1.5em 0.5em 0;
}

.password_input {
  width: 100%;
  padding: 0.7em;
  margin-bottom: 0.5rem;
}

.cs_image :deep(img) {
  height: 3.5rem;
}
.c_info_panel {
  border-radius: 0.5rem;
  border-style: solid;
  border-width: 0.01rem;
  background-color: #f8fafc;
  border-color: #aaa;
  display: grid;
  padding: 1rem;
}
/*
.password_input:focus {
opacity: 100%;
outline: 3px solid gray;
}
*/

/*
@media (min-width: 400px) {
  form {
    overflow: hidden;
  }

  label.form_label {
    float: left;
    width: 8rem;
  }

  .password_input {
    float: left;
    width: calc(100% - 8rem);
  }
}
 */
 .cs_bottom_center {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 1rem; /* Adjust the margin as needed */
}
</style>
